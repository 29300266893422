import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { setUser } from "../store/slices/userSlice";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export default function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        (async function() {
            let usersData;

            await getDoc(doc(db, "stores", "stores"))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        usersData = snapshot.data();
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            if (usersData) {
                setUsers(usersData);
                setLoading(false);
            }
        })();
    },[]);

    async function onEnterClick() {
        const username = email.split('@')[0];
        let userRole = '';

        for (let user in users) {
            if (user === username) {
                if (users[user].role) {
                    if (users[user].role !== 'tester') {
                        userRole = users[user].role;
                        break;
                    }
                } else {
                    return;
                }
            }
        }

        signInWithEmailAndPassword(auth, email, password)
            .then(({ user }) => {
                dispatch(setUser({
                    email: user.email,
                    id: user.uid,
                    role: userRole,
                    token: user.accessToken,
                }));
                navigate('/');
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return loading ? (
        <></>
    ) : (
        <div className="container col-xl-10 col-xxl-8 px-4 py-5">
            <img
                src={require('../assets/icon.png')}
                height={75}
                width={75}
                className={'d-block mx-auto mb-4'}
            />
            <div className="row align-items-center g-lg-5 py-5">
                <div className="col-md-10 mx-auto col-lg-5">
                    <form className="p-4 p-md-5 border rounded-3 bg-light">
                        <div className="form-outline mb-4 justify-content-sm-center">
                            <input
                                type="email"
                                className="form-control form-control-lg"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Логин"
                            />
                        </div>
                        <div className="form-outline mb-4 justify-content-sm-center">
                            <input
                                type="password"
                                className="form-control form-control-lg"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Пароль"
                            />
                        </div>
                        <button type="button" className="btn btn-primary w-100" onClick={onEnterClick}>Войти</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
