import { Chart as ChartJS, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement)

export function PieChart({ data = [300, 50]
}) {

    const chartData = {
        labels: [
            'Верно',
            'Неверно',
        ],
        datasets: [
            {
                label: '',
                data: data,
                backgroundColor: [
                    'green',
                    'red',
                ],
                hoverOffset: 4
            }
        ]
    };


    const options = {}

    return (
        <div>
            <Pie data={chartData} options={options} />
        </div>
    )
}
