import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    test: null,
    ans: null,
};

const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        setTest(state, action) {
            state.test = action.payload.test;
            state.ans = action.payload.ans;
        },
    },
});


export const { setTest } = testSlice.actions;

export default testSlice.reducer;
