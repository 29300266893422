import { useEffect, useState } from "react";
import { storage } from "../firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { PictureGallery } from "../components/PictureGallery";
import { Modal } from 'react-bootstrap';

export default function PictureGalleryPage({ test, answers, users }) {
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        (async function() {
            let photosArray = [];
            let photosFromStorage = {};
            for (let answer in answers) {
                for (let j = 0; j < answers[answer].length; j++) {
                    if (typeof answers[answer][j] === "string") {
                        if (answers[answer][j].substring(0, 5) === 'tests') {
                            if (sessionStorage.photos) {
                                photosFromStorage = JSON.parse(sessionStorage.photos);
                            }
                            let question = '';
                            let trueAns = 0;
                            for (let p = 0; p < test["questions"].length; p++) {
                                if (test["questions"][p]["type"] === 'consecutive') {
                                    if (answers[answer][p + trueAns][0]) {
                                        if (p + trueAns + 1 === j) {
                                            trueAns++;
                                            question = test["questions"][p].text;
                                            break;
                                        } else {
                                            trueAns++;
                                        }
                                    }
                                }
                            }
                            question = test["questions"][j - trueAns].text;
                            if (photosFromStorage[answers[answer][j]]) {
                                photosArray.push({
                                    path: answers[answer][j],
                                    url: photosFromStorage[answers[answer][j]],
                                    question: question,
                                    storeName: answer,
                                });
                            } else {
                                await getDownloadURL(ref(storage, answers[answer][j]))
                                    .then((url) => {
                                        photosArray.push({
                                            path: answers[answer][j],
                                            url: url,
                                            question: question,
                                            storeName: answer,
                                        });
                                        sessionStorage.photos = JSON.stringify({
                                            ...photosFromStorage,
                                            [answers[answer][j]]: url
                                        })
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        photosArray.push({
                                            path: answers[answer][j],
                                            url: null,
                                            question: question,
                                            storeName: answer,
                                        });
                                    });
                            }
                        }
                    }
                }
            }
            setCurrentPage(1);
            setPhotos(photosArray);
        })();
    },[test]);

    useEffect(() => {
        setLoading(false);
    }, [photos]);

    useEffect(() => {
        setLoading(true);
    }, [test]);

    function onClick(index) {
        setCurrentPhoto(photos[index])
        setModalVisible(true);
    }

    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
    }

    if (loading) {
        return (
            <div className="container">
                <p>Загрузка...</p>
            </div>

        )
    }

    return (
        <div className="container">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h2>Фотоальбом</h2>
                {/*<div className="btn-toolbar mb-2 mb-md-0">*/}
                {/*    <div className="btn-group me-2">*/}
                {/*        <button type="button" className="btn btn-primary">Обновить</button>*/}
                {/*    </div>*/}
                {/*    <div className="btn-group me-2">*/}
                {/*        <button type="button" className="btn btn-primary">Экспорт</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/*<div className="flex-fill border p-3 m-3 bg-white rounded shadow row">*/}
            {/*    <select className="form-select col" id="country" required="">*/}
            {/*        <option value="">Регион</option>*/}
            {/*        <option>Москва</option>*/}
            {/*        <option>Москва3</option>*/}
            {/*    </select>*/}
            {/*    <select className="form-select col mx-2" id="country" required="">*/}
            {/*        <option value="">Вопрос</option>*/}
            {/*        <option>Вопрос 1</option>*/}
            {/*        <option>Вопрос 2</option>*/}
            {/*    </select>*/}
            {/*    <form className="col">*/}
            {/*        <input className="form-control" type="text" placeholder="Поиск" aria-label="Search"/>*/}
            {/*    </form>*/}
            {/*</div>*/}
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                <PictureGallery photosArray={photos} onPhotoClick={(index) => onClick(index)} paginate={paginate} currentPage={currentPage} />
            </div>
            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img
                        alt="amg"
                        src={currentPhoto.url}
                        width="100%"
                        height="100%"
                        className="d-inline-block align-top mx-1 my-1"
                    />
                    <h3>Инфо</h3>
                    <h5>Вопрос</h5>
                    <h6>{currentPhoto.question}</h6>
                    <h5>Название магазина</h5>
                    {
                        currentPhoto.storeName ?
                            <>
                                <h6>{users[currentPhoto.storeName]["name"]}</h6>
                                <h5>Код магазина</h5>
                                <h6>{users[currentPhoto.storeName]["code"]}</h6>
                                <h5>Город</h5>
                                <h6>{users[currentPhoto.storeName]["city"]}</h6>
                            </>
                            :
                            <></>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

