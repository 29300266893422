import { useEffect, useState } from 'react';
import { get, ref } from 'firebase/database';
import { database, db } from '../firebaseConfig';
import { QuestionCard } from '../components/QuestionCard';
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";

const LiveReportPageType = {
    primary: 'primary',
    moreInfo: 'moreInfo'
}

export default function LiveReportPage({ selectedTest }) {
    const { email } = useAuth();
    const [currentTest, setCurrentTest] = useState(selectedTest);
    const [pageType, setPageType] = useState(LiveReportPageType.primary);
    const [loading, setLoading] = useState(true);
    const [answers, setAnswers] = useState({});
    const [storesInfo, setStoresInfo] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState(0);

    useEffect(() => {
        setCurrentTest(selectedTest);
        setLoading(true);
    },[selectedTest]);

    useEffect(() => {
        setPageType(LiveReportPageType.primary);
        setCurrentQuestion(0);
        (async function() {
            const username = email.split('@')[0];
            let storesInfoData = {};
            let managerData = {}

            const docRef = doc(db, "stores", "stores");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                storesInfoData = docSnap.data();
            } else {
                console.log("No such document!");
            }

            await get(ref(database, 'managers_info/' + selectedTest.id))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        managerData = snapshot.val();
                    } else {
                        console.log('No data available');
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            await get(ref(database, 'tests/' + currentTest.id))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        let answersMap = {};
                        let isFiltered = false;
                        let filterKey = '';
                        let filterData = '';
                        if (storesInfoData[username].filter) {
                            isFiltered = true;
                            filterKey = Object.keys(storesInfoData[username].filter)[0];
                            filterData = Object.values(storesInfoData[username].filter)[0];
                        }
                        const answersData = snapshot.val();
                        const answersValues = Object.values(answersData);
                        const answersKeys = Object.keys(answersData);
                        for (let i = 0; i < answersKeys.length; i++) {
                            if (!storesInfoData[answersKeys[i]].role) {
                                if (!managerData[answersKeys[i]] || managerData[answersKeys[i]]['finish_time']) {
                                    if (isFiltered) {
                                        if (storesInfoData[answersKeys[i]][filterKey] === filterData) {
                                            answersMap = {
                                                ...answersMap,
                                                [answersKeys[i]]: answersValues[i]
                                            }
                                        }
                                    } else {
                                        answersMap = {
                                            ...answersMap,
                                            [answersKeys[i]]: answersValues[i]
                                        }
                                    }
                                }
                            }
                        }
                        setAnswers(answersMap);
                        setLoading(false);
                    } else {
                        console.log('No data available');
                        setAnswers({});
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            setStoresInfo(storesInfoData);
        })();
    },[loading]);

    if (loading) {
        return (
            <></>
        )
    }

    function onMoreInfoCLick(index) {
        setPageType(LiveReportPageType.moreInfo);
        setCurrentQuestion(index);
    }

    function onBackToAllQuestionsClick() {
        setPageType(LiveReportPageType.primary);
    }

    function onNextQuestionClick() {
        if (currentQuestion !== currentTest.questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        }
    }

    function onPreviousQuestionClick() {
        if (currentQuestion) {
            setCurrentQuestion(currentQuestion - 1);
        }
    }

    function AnswerRow({ storeName }) {
        let missNum = 0;
        for (let i = 0; i < currentQuestion; i++) {
            if (currentTest.questions[i].type === 'consecutive') {
                if (answers[storeName][i + missNum]) {
                    if (answers[storeName][i + missNum][0]) {
                        missNum++;
                    }
                }
            }
        }

        return currentTest.questions[currentQuestion].type === 'open' ? (
            <td>{answers[storeName][currentQuestion + missNum]}</td>
        ) : (
            answers[storeName][currentQuestion + missNum].map((answer, index) => <td key={index}>{answer ? currentTest.questions[currentQuestion].answers[index].text : ''}</td>)
        )
    }

    return loading ? (
        <></>
    ) : (
        <div className="container">
            {
                pageType === 'primary' ?
                    <>
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                            <h2>Живой отчет</h2>
                        </div>
                        {
                            currentTest?.questions?.map((question, index) =>
                                <div key={index}>
                                    <QuestionCard
                                        selectedTest={currentTest}
                                        index={index}
                                        onMoreInfoClick={(index) => onMoreInfoCLick(index)}
                                        question={question}
                                        storesInfo={storesInfo}
                                        answers={answers}
                                    />
                                </div>
                            )
                        }
                    </>
                    :
                    <>
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                            <h2>Живой отчет</h2>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="btn-group me-2">
                                    <button type="button" className="btn btn-primary" onClick={onBackToAllQuestionsClick}>К списку вопросов</button>
                                </div>
                                <div className="btn-group me-2">
                                    <button type="button" className="btn btn-primary" onClick={onPreviousQuestionClick}>Предыдущий вопрос</button>
                                </div>
                                <div className="btn-group me-2">
                                    <button type="button" className="btn btn-primary" onClick={onNextQuestionClick}>Следующий вопрос</button>
                                </div>
                            </div>
                        </div>
                        {
                            currentTest.questions[currentQuestion] ?
                                <>
                                    <QuestionCard
                                        index={currentQuestion}
                                        question={currentTest.questions[currentQuestion]}
                                        selectedTest={currentTest}
                                        answers={answers}
                                        moreInfo={currentTest.questions[currentQuestion].type === 'photo' || currentTest.questions[currentQuestion].type === 'open'}
                                        storesInfo={storesInfo}
                                    />
                                    {
                                        currentTest.questions[currentQuestion].type === 'photo' ?
                                            <></>
                                            :
                                            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Код магазина</th>
                                                        <th scope="col">Наименование магазина</th>
                                                        {
                                                            currentTest.questions[currentQuestion].type === 'open' ?
                                                                <th scope="col">Ответ</th>
                                                                :
                                                                currentTest.questions[currentQuestion].answers.map((answer, index) =>  <th key={index}>{answer.text}</th>)
                                                        }
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        Object.keys(answers).map((storeName) =>
                                                            <tr key={storeName}>
                                                                <td>{storesInfo[storeName].code}</td>
                                                                <td>{storesInfo[storeName].name}</td>
                                                                <AnswerRow storeName={storeName} />
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </>
                            :
                            <></>
                        }
                    </>
            }
        </div>
    )
}

