import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from 'firebase/database'

// Initialize Firebase
export const firebaseConfig = {
  apiKey: 'AIzaSyDAwTXII8TB7OBzPCItxeJnPmjBhbn5X6U',
  authDomain: 'storus-a64c6.firebaseapp.com',
  projectId: 'storus-a64c6',
  storageBucket: 'storus-a64c6.appspot.com',
  appId: '1:411822579809:ios:ceb921343807b23ee94a20',
  databaseURL: 'https://storus-a64c6-default-rtdb.europe-west1.firebasedatabase.app/'
};

export const firebaseConfigWeb = {
  apiKey: "AIzaSyD_YabrwKQselgjv5mm_4ZkWD6asa1028c",
  authDomain: "storusweb.firebaseapp.com",
  projectId: "storusweb",
  storageBucket: "storusweb.appspot.com",
  messagingSenderId: "240088088455",
  appId: "1:240088088455:web:87899529a5e6d9f634dd22",
  measurementId: "G-WBWESMGGM3"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
