import {PieChart} from "../components/PieChart";

export default function ActivationPage() {
    return (
        <div className="container">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h2>Общая активация</h2>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-primary">Экспорт</button>
                    </div>
                </div>
            </div>
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow row">
                <select className="form-select col" id="country" required="">
                    <option value="">Регион</option>
                    <option>Москва</option>
                    <option>Москва3</option>
                </select>
                <form className="col">
                    <input className="form-control" type="text" placeholder="Search" aria-label="Search"/>
                </form>
            </div>
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                <h2>Вид графиков</h2>
                <div className="row">
                    <div className="col">
                        <h6>Влияние печатных материалов</h6>
                        <PieChart />
                    </div>
                    <div className="col">
                        <h6>Показать влияние</h6>
                        <PieChart />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6>Наличие товара</h6>
                        <PieChart />
                    </div>
                    <div className="col">
                        <h6>Выдача марок</h6>
                        <PieChart />
                    </div>
                </div>
            </div>
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                <h2>Участвующие магазины</h2>
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th scope="col">Код магазина</th>
                            <th scope="col">Название магазина</th>
                            <th scope="col">Влияние печатных материалов</th>
                            <th scope="col">Показать влияние</th>
                            <th scope="col">Наличие товара</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>10</td>
                            <td>Москва</td>
                            <td>100%</td>
                            <td>N/A</td>
                            <td>78.9%</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Москва_СВАО</td>
                            <td>100%</td>
                            <td>N/A</td>
                            <td>100%</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Москва_ЮАО</td>
                            <td>83.3%</td>
                            <td>N/A</td>
                            <td>78.9%</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


