import { BarChart } from "./BarChart";
import { useEffect, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebaseConfig";
import { PictureGallery } from "./PictureGallery";
import { Modal } from "react-bootstrap";

export function QuestionCard({ question = [], selectedTest, answers, index, storesInfo, onMoreInfoClick = null, moreInfo = false }) {
    const [photos, setPhotos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState({});
    const totalAnswers = Object.keys(answers).length;
    let chartDatasets  = [
        {
            label: '',
            data: [0],
        }
    ]

    useEffect(() => {
        (async function() {
            if (question.type === 'photo') {
                const answersArray = Object.values(answers);
                let photosArray = [];
                for (let i = 0; i < answersArray.length; i++) {
                    await getDownloadURL(ref(storage, answersArray[i][index]))
                        .then((url) => {
                            photosArray.push({
                                url: url,
                                storeName: Object.keys(answers)[i],
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            photosArray.push({
                                url: null,
                                storeName: Object.keys(answers)[i],
                            });
                        });
                }
                setPhotos(photosArray);
            } else if (question.type === 'consecutive') {
                const answersValues = Object.values(answers);
                const answersKeys = Object.keys(answers);
                let photosArray = [];
                let totalPhotos = 0;
                for (let i = 0; i < answersKeys.length; i++) {
                    if (answersValues[i][index]) {
                        let missNum = 0;
                        for (let p = 0; p < index; p++) {
                            if (selectedTest.questions[p].type === 'consecutive') {
                                if (answersValues[i][p + missNum][0]) {
                                    missNum++;
                                }
                            }
                        }
                        if (answersValues[i][index + missNum]) {
                            if (answersValues[i][index + missNum][0]) {
                                totalPhotos++;
                                await getDownloadURL(ref(storage, answersValues[i][index + missNum + 1]))
                                    .then((url) => {
                                        photosArray.push({
                                            url: url,
                                            storeName: answersKeys[i],
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        photosArray.push({
                                            url: null,
                                            storeName: answersKeys[i],
                                        });
                                    });
                            }
                        }
                    }
                }
                setPhotos(photosArray);
            }
        })();
    },[answers]);

    function onPhotoClick(index) {
        setModalVisible(true);
        setCurrentPhoto(photos[index]);
    }

    function Answer({ answer, answerNum }) {
        let total = 0;
        for (let i in answers) {
            if (answers[i][index]) {
                let missNum = 0;
                for (let p = 0; p < index; p++) {
                    if (selectedTest.questions[p].type === 'consecutive') {
                        if (answers[i][p + missNum][0]) {
                            missNum++;
                        }
                    }
                }
                if (answers[i][index + missNum]) {
                    if (answers[i][index + missNum][answerNum]) {
                        total++;
                    }
                }
            }
        }

        chartDatasets[answerNum] = {
            label: answer.text,
            data: [total, totalAnswers],
            backgroundColor: `hsl(216, 98%, ${100 - answerNum * 15}%)`,
            borderColor: 'black',
            borderWidth: 1
        };

        return (
            <tr>
                <td>{answer.text}</td>
                <td>{total}</td>
                <td>{Math.round(total / totalAnswers * 100) || 0}%</td>
            </tr>
        )
    }

    function PhotoAnswer({ index, source }) {
        const [onFocus, setFocus] = useState(false);

        return (
            <>
                <img
                    // onMouseEnter={() => setFocus(true)}
                    // onMouseLeave={() => setFocus(false)}
                    onClick={() => onPhotoClick(index)}
                    alt="amg"
                    src={source}
                    height={250}
                    width={250}
                    className="d-inline-block align-top mx-1 my-1"
                />
            </>
        )
    }

    function OpenAnswer() {
        let openAnswers = [];
        for (let i in answers) {
            let missNum = 0;
            for (let j = 0; j < index; j++) {
                if (selectedTest.questions[j].type === 'consecutive') {
                    if (answers[i][j + missNum]) {
                        if (answers[i][j + missNum][0]) {
                            missNum++;
                        }
                    }
                }
            }
            openAnswers.push(answers[i][index + missNum]);
        }


        return (
            openAnswers.length < 5 ?
                openAnswers.map((answer, index) => (
                    <p key={index}>{answer}</p>
                ))
                :
                <>
                    {
                        openAnswers.slice(0,5).map((answer, index) => (
                            <p key={index}>{answer}</p>
                        ))
                    }
                    <p>. . .</p>
                </>
        )
    }

    return (
        <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <h5>{question.text} ({question.topic})</h5>
                {
                    onMoreInfoClick ?
                        <div className="btn-toolbar">
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary" onClick={() => onMoreInfoClick(index)}>Подробнее</button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            {
                question.type === 'photo' ?
                    moreInfo ?
                        <PictureGallery photosArray={photos} onPhotoClick={(index) => onPhotoClick(index)} />
                        :
                        <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }}>
                            {
                                photos.map((photo, index) => <PhotoAnswer key={index} index={index} source={photo.url} />)
                            }
                        </div>
                    : question.type === 'open' ?
                        moreInfo ?
                            <></>
                            :
                            <OpenAnswer />
                        :
                        <div className="row">
                            <div className="col">
                                <BarChart datasets={chartDatasets} />
                            </div>
                            <div className="col">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Ответ</th>
                                        <th scope="col">Всего</th>
                                        <th scope="col">Процент</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        question?.answers?.map((answer, index) => <Answer key={index} answer={answer} answerNum={index} />)
                                    }
                                    </tbody>
                                </table>
                            </div>
                            {
                                question.type === 'consecutive' ?
                                    <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }}>
                                        {
                                            photos.map((photo, index) => <PhotoAnswer key={index} index={index} source={photo.url} />)
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
            }
            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <img
                        alt="amg"
                        src={currentPhoto.url}
                        width="100%"
                        height="100%"
                        className="d-inline-block align-top mx-1 my-1"
                    />
                    <h4>Инфо</h4>
                    <h5>Вопрос</h5>
                    <h6>{question.text}</h6>
                    <h5>Название магазина</h5>
                    {
                        currentPhoto.storeName ?
                            <>
                                <h6>{storesInfo[currentPhoto.storeName].name}</h6>
                                <h5>Код магазина</h5>
                                <h6>{storesInfo[currentPhoto.storeName].code}</h6>
                                <h5>Город</h5>
                                <h6>{storesInfo[currentPhoto.storeName].city}</h6>
                            </>
                            :
                            <></>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
