import { BarChart } from "../components/BarChart";
import { useEffect, useState } from "react";
import { PieChart } from "../components/PieChart";

export default function TaskProgressPage({ test, answers, users, managers }) {
    const [loading, setLoading] = useState(true);
    const [participatingStores, setParticipatingStores] = useState([]);
    const [performingStores, setPerformingStores] = useState(0);
    const [completedStores, setCompletedStores] = useState(0);
    const [chartCompletedStores, setChartCompletedStores] = useState([0]);
    const [chartFailedStores, setChartFailedStores] = useState([0]);
    const [topics, setTopics] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc");
    let chartDatasets  = [
        {
            label: "Выполнившие",
            data: chartCompletedStores,
            backgroundColor: "green",
            borderColor: "black",
            borderWidth: 1
        },
        {
            label: "Не выполнившие",
            data: chartFailedStores,
            backgroundColor: "red",
            borderColor: "black",
            borderWidth: 1
        }
    ];

    useEffect(() => {
        (async function() {
            const startedStores = Object.keys(answers).length;
            let performingStores = 0;

            for (let manager in managers) {
                if (!managers[manager]["finish_time"]) performingStores++;
            }

            if (test["topics"]) {
                let topicsData = [];
                for (let i = 0; i < test["topics"].length; i++) {
                    let rightAnswers = 0;
                    let topicQuestions = 0;
                    for (let j = 0; j < test["questions"].length; j++) {
                        if (test["topics"][i] === test["questions"][j]["topic"]) {
                            topicQuestions++;
                            let rightAnswer = 0;
                            for (let u = 0; u < test["questions"][j]["answers"].length; u++) {
                                if (test["questions"][j]["answers"][u]["isRight"]) {
                                    rightAnswer = u;
                                }
                            }
                            for (let answer in answers) {
                                if (!managers[answer] || managers[answer]['finish_time']) {
                                    let trueAns = 0;
                                    for (let a = 0; a < j; a++) {
                                        if (test["questions"][a]["type"] === 'consecutive') {
                                            if (answers[answer][a + trueAns][0]) {
                                                trueAns++;
                                            }
                                        }
                                    }
                                    if (answers[answer][j + trueAns][rightAnswer]) {
                                        rightAnswers++;
                                    }
                                }
                            }
                        }
                    }
                    topicsData.push({
                        topic: test["topics"][i],
                        rightAnswers: rightAnswers,
                        falseAnswers: (startedStores - performingStores) * topicQuestions - rightAnswers,
                    });
                }
                setTopics(topicsData);
            } else {
                setTopics([]);
            }

            let consecutiveQuestionsCount = 0;
            for (let i = 0; i < test["questions"].length; i++) {
                if (test["questions"][i]["type"] === 'consecutive') {
                    consecutiveQuestionsCount++;
                }
            }
            const questionsCount = test["questions"].length + consecutiveQuestionsCount;
            for (let user in users) {
                let answersCount;
                let status;
                let progress;
                if (!managers[user]) {
                    if (answers[user]) {
                        answersCount = questionsCount + "/" + questionsCount;
                        status = "Завершено";
                        progress = "100%";
                    } else {
                        answersCount = 0 + "/" + questionsCount;
                        status = "Не выполнено";
                        progress = "0%";
                    }
                } else {
                    let trueAns = 0;
                    let falseAns = 0;
                    for (let i = 0; i < test["questions"].length; i++) {
                        if (test["questions"][i].type === 'consecutive') {
                            if (answers[user][i + trueAns]) {
                                if (answers[user][i + trueAns][0]) {
                                    trueAns++;
                                } else {
                                    falseAns++;
                                }
                            } else {
                                break;
                            }
                        }
                    }
                    if ((answers[user].length + falseAns) < questionsCount) {
                        progress = Math.round(answers[user].length * 100 / questionsCount) + "%";
                        status = "Выполняется";
                    } else {
                        progress = "100%";
                        status = "Завершено";
                    }
                    answersCount = (answers[user].length + falseAns) + "/" + questionsCount;
                }
                users[user].answersCount = answersCount;
                users[user].status = status;
                users[user].progress = progress;
            }
            sortTable("code", Object.values(users), "asc");

            setPerformingStores(performingStores);
            setCompletedStores(startedStores - performingStores);
            setChartCompletedStores([startedStores - performingStores]);
            setChartFailedStores([Object.values(users).length - startedStores + performingStores]);
            setLoading(false);
        })();
    },[test]);

    if (loading) {
        return (
            <></>
        )
    }

    function sortTable(column, table = participatingStores, sortDir = sortDirection) {
        let sortedTable = table.sort((a, b) => {
            let lastValueA = a[column];
            let lastValueB = b[column];
            if (sortDir === "asc") {
                return lastValueA.localeCompare(lastValueB);
            } else {
                return lastValueB.localeCompare(lastValueA);
            }
        });
        setParticipatingStores(sortedTable);
        setSortDirection(sortDir === "asc" ? "desc" : "asc");
    }

    return (
        <div className="container">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h2>Прогресс</h2>
                {/*<div className="btn-toolbar mb-2 mb-md-0">*/}
                {/*    <div className="btn-group me-2">*/}
                {/*        <button type="button" className="btn btn-primary">Экспорт</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/*<div className="flex-fill border p-3 m-3 bg-white rounded shadow row">*/}
            {/*    <select className="form-select col" id="country" required="">*/}
            {/*        <option value="">Регион</option>*/}
            {/*        <option>Москва</option>*/}
            {/*        <option>Москва3</option>*/}
            {/*    </select>*/}
            {/*    <select className="form-select col mx-2" id="status" required="">*/}
            {/*        <option value="">Статус</option>*/}
            {/*        <option>Завершено</option>*/}
            {/*        <option>В процессе</option>*/}
            {/*        <option>Не начато</option>*/}
            {/*    </select>*/}
            {/*    <form className="col">*/}
            {/*        <input className="form-control" type="text" placeholder="Search" aria-label="Search"/>*/}
            {/*    </form>*/}
            {/*</div>*/}
            <div className="row">
                <div className="col border p-3 m-3 bg-white rounded shadow">
                    <h2>Обзор</h2>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Уровень вовлеченности</td>
                            <td>{Math.round(completedStores / participatingStores.length * 100)}%</td>
                        </tr>
                        <tr>
                            <td>Участвующие магазины</td>
                            <td>{participatingStores.length}</td>
                        </tr>
                        <tr>
                            <td>Выполнившие магазины</td>
                            <td>{completedStores}</td>
                        </tr>
                        <tr>
                            <td>Выполняющие магазины</td>
                            <td>{performingStores}</td>
                        </tr>
                        <tr>
                            <td>Не выполнившие магазины</td>
                            <td>{participatingStores.length - completedStores}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col border p-3 m-3 bg-white rounded shadow">
                    <h2>В виде графика</h2>
                    {
                        participatingStores ? <BarChart datasets={chartDatasets} /> : <></>
                    }

                </div>
            </div>
            {
                test["topics"] ?
                    <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                        <h2>Топики</h2>
                        <div className="row justify-content-center">
                            {
                                topics.map((data) => (
                                    <div style={{ width: 250 }} key={data.topic}>
                                        <h6 style={{ textAlign: 'center' }}>{data.topic}</h6>
                                        <PieChart data={[data.rightAnswers, data.falseAnswers]}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <></>
            }
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                <h2>Участвующие магазины</h2>
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th scope="col" onClick={() => sortTable('code')} style={{ textAlign: 'center' }}>Код магазина</th>
                            <th scope="col" onClick={() => sortTable('name')} style={{ textAlign: 'center' }}>Название магазина</th>
                            <th scope="col" onClick={() => sortTable('city')} style={{ textAlign: 'center' }}>Город</th>
                            <th scope="col" onClick={() => sortTable('answersCount')} style={{ textAlign: 'center' }}>Вопросы и ответы</th>
                            <th scope="col" onClick={() => sortTable('progress')} style={{ textAlign: 'center' }}>Прогресс</th>
                            <th scope="col" onClick={() => sortTable('status')} style={{ textAlign: 'center' }}>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            participatingStores.map((store, index) =>
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{store["code"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["name"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["city"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["answersCount"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["progress"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["status"]}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
