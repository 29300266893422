import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice'
import testReducer from './slices/testSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        test: testReducer,
    }
})
