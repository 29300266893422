import "./App.css";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { database, db } from "./firebaseConfig";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import TaskProgressPage from "./pages/TaskProgressPage";
import ActivationPage from "./pages/ActivationPage";
import PictureGalleryPage from "./pages/PictureGalleryPage";
import LiveReportPage from "./pages/LiveReportPage";
import ActivationOverallPage from "./pages/ActivationOverallPage";
import ExportPage from "./pages/ExportPage";
import LoginPage from "./pages/LoginPage";
import { get, ref } from "firebase/database";
import ManagerRatingPage from "./pages/ManagerRatingPage";
import { useAuth } from "./hooks/useAuth";

function App({ history }) {
    const location = useLocation();
    const isAuth = !!localStorage.user;

    const { email } = useAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [users, setUsers] = useState(null);
    const [tests, setTests] = useState(null);
    const [currentTest, setCurrentTest] = useState(null);
    const [answers, setAnswers] = useState(null);
    const [currentTestAnswers, setCurrentTestAnswers] = useState(null);
    const [managers, setManagers] = useState(null);
    const [currentTestManagers, setCurrentTestManagers] = useState(null);

    useEffect(() => {
        (async function() {
            if (isAuth) {
                const username = email.split('@')[0];
                let testsData;
                let answersData;
                let usersData;
                let managersData;

                await get(ref(database, 'tests/'))
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            answersData = snapshot.val();
                        } else {
                            console.log('No data available');
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })

                await get(ref(database, 'managers_info/'))
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            managersData = snapshot.val();

                        } else {
                            console.log('No data available');
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })

                await getDoc(doc(db, "tests", "tests"))
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            testsData = snapshot.data().tests;
                        } else {
                            console.log("No such document!");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                await getDoc(doc(db, "stores", "stores"))
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            usersData = snapshot.data();
                            let filterKey;
                            let filterData;
                            if (usersData[username].filter) {
                                filterKey = Object.keys(usersData[username].filter)[0];
                                filterData = Object.values(usersData[username].filter)[0];
                            }
                            for (let user in usersData) {
                                if (usersData[user].role) {
                                    delete usersData[user];
                                    for (let testName in answersData) {
                                        delete answersData[testName][user];
                                    }
                                    for (let testName in managersData) {
                                        delete managersData[testName][user];
                                    }
                                } else {
                                    if (filterKey) {
                                        if (usersData[user][filterKey] !== filterData) {
                                            delete usersData[user];
                                            for (let testName in answersData) {
                                                delete answersData[testName][user];
                                            }
                                            for (let testName in managersData) {
                                                delete managersData[testName][user];
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            console.log("No such document!");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })

                if (testsData && answersData && usersData && managersData) {
                    setUsers(usersData);
                    setTests(testsData);
                    setCurrentTest(testsData[0]);
                    setAnswers(answersData);
                    setCurrentTestAnswers(answersData[testsData[0].id]);
                    setManagers(managersData);
                    setCurrentTestManagers(managersData[testsData[0].id])
                    setLoading(false);
                } else {
                    setError(true);
                }
            }
        })();
    },[isAuth]);

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    function onSelectedTestChange(event) {
        const selectedTest = tests[event.target.value];
        setCurrentTestAnswers(answers[selectedTest.id]);
        setCurrentTestManagers(managers[selectedTest.id]);
        setCurrentTest(selectedTest);
    }

    return !isAuth ? (
        <>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="*" element={<LoginPage />} />
            </Routes>
        </>
    ) : loading ? (
        <></>
    ) : error ? (
        <div className="container col-xl-10 col-xxl-8 px-4 py-5">
            <img
                alt="amg"
                src={require('./assets/icon.png')}
                height={75}
                width={75}
                className={'d-block mx-auto mb-4'}
            />
            <div className="row align-items-center g-lg-5 py-5 text-center">
                <div className="col-md-10 mx-auto col-lg-5">
                    <p>Произошла ошибка при получении данных. Пожалуйста, проверьте Ваше соединение с интернетом и повторите попытку.</p>
                </div>
            </div>
        </div>
    ) : (
        <>
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div className="d-flex flex-wrap align-self-center justify-content-center justify-content-lg-center">
                        <a href="/" className="d-flex align-self-center mb-2 mb-lg-0">
                            <img
                                alt="amg"
                                src={require('./assets/icon.png')}
                                height={40}
                                width={40}
                                className={'d-inline-block align-top'}
                            />
                        </a>

                        <ul className="nav nav-pills col-12 col-lg-auto me-lg-auto mb-2 justify-content-center">
                            <li className="px-2 mx-2">
                                <div
                                    type="button"
                                    className={"nav-link"}
                                    onClick={() => {
                                        localStorage.user = '';
                                        window.location.reload(true);
                                    }}>Выйти
                                </div>
                            </li>
                            <li className="px-2 mx-2">
                                <Link
                                    to="/"
                                    className={currentPath === "/" ? "nav-link active" : "nav-link"}
                                    onClick={() => setCurrentPath("/")}>Прогресс
                                </Link>
                            </li>
                            {/*<li className="px-2 mx-2">*/}
                            {/*    <Link*/}
                            {/*        to="/activation"*/}
                            {/*        className={selectedPath === "/activation" ? "nav-link active" : "nav-link"}*/}
                            {/*        onClick={() => setSelectPath("/activation")}>Очки за активацию*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className="px-2 mx-2">
                                <Link
                                    to="/pictureGallery"
                                    className={currentPath === "/pictureGallery" ? "nav-link active" : "nav-link"}
                                    onClick={() => setCurrentPath("/pictureGallery")}>Фотоальбом
                                </Link>
                            </li>
                            <li className="px-2 mx-2">
                                <Link
                                    to="/liveReport"
                                    className={currentPath === "/liveReport" ? "nav-link active" : "nav-link"}
                                    onClick={() => setCurrentPath("/liveReport")}>Живой отчет
                                </Link>
                            </li>
                            <li className="px-2 mx-2">
                                <Link
                                    to="/managerRating"
                                    className={currentPath === "/managerRating" ? "nav-link active" : "nav-link"}
                                    onClick={() => setCurrentPath("/managerRating")}>Оценка менеджера
                                </Link>
                            </li>
                            {/*<li className="px-2 mx-2">*/}
                            {/*    <Link*/}
                            {/*        to="/activationOverall"*/}
                            {/*        className={currentPath === "/activationOverall" ? "nav-link active" : "nav-link"}*/}
                            {/*        onClick={() => setCurrentPath("/activationOverall")}>Всего очков за активацию*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li className="px-2 mx-2">*/}
                            {/*    <Link*/}
                            {/*        to="/export"*/}
                            {/*        className={currentPath === "/export" ? "nav-link active" : "nav-link"}*/}
                            {/*        onClick={() => setCurrentPath("/export")}>Экспорт*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                        </ul>
                        <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <select className="form-select" id="test" onChange={onSelectedTestChange}>
                                {
                                    tests.map((test, index) => {
                                        return (
                                            <option key={test.id} value={index}>{test.title}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </header>

            <Routes>
                <Route path="/" element={
                    <TaskProgressPage
                        test={currentTest}
                        answers={currentTestAnswers}
                        users={users}
                        managers={currentTestManagers} />
                } />
                <Route path="/activation" element={<ActivationPage />} />
                <Route path="/pictureGallery" element={
                    <PictureGalleryPage
                        test={currentTest}
                        answers={currentTestAnswers}
                        users={users} />
                } />
                <Route path="/liveReport" element={<LiveReportPage selectedTest={currentTest} />} />
                <Route path="/activationOverall" element={<ActivationOverallPage />} />
                <Route path="/export" element={<ExportPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/managerRating" element={<ManagerRatingPage selectedTest={currentTest} />} />
                <Route path="*" element={
                    <TaskProgressPage
                        test={currentTest}
                        answers={currentTestAnswers}
                        users={users}
                        managers={currentTestManagers} />
                } />
            </Routes>
        </>
    )
}

export default App;
