import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
)

export function BarChart({ datasets  = [
        {
            label: 'Да',
            data: [3],
            backgroundColor: 'orange',
            borderColor: 'black',
            borderWidth: 1
        },
        {
            label: 'Нет',
            data: [1],
            backgroundColor: 'yellow',
            borderColor: 'black',
            borderWidth: 1
        },
    ]}) {
    const data = {
        labels: [""],
        datasets: datasets
    }

    const options = {}


    return (
        <div>
            <Bar data={data} options={options}></Bar>
        </div>
    )
}
