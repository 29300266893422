import {useEffect, useState} from "react";
import {get, ref} from "firebase/database";
import {database, db} from "../firebaseConfig";
import {doc, getDoc} from "firebase/firestore";
import {useAuth} from "../hooks/useAuth";
import {Modal} from "react-bootstrap";
import { ref as databaseRef, update } from 'firebase/database';
import { getStorage, ref as storageRef, listAll, getDownloadURL } from "firebase/storage";

export default function ManagerRatingPage({ selectedTest }) {
    const { email } = useAuth();
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(true);
    const [managerInfo, setManagerInfo] = useState({});
    const [storesInfo, setStoresInfo] = useState([]);
    const [storesInfoKeys, setStoresInfoKeys] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    StoreScore();
    useEffect(() => {
        (async function() {
            const username = email.split('@')[0];
            let participatingStores = 0;
            let storesInfoValues = [];
            let storesInfoKeys = [];
            let managerData = {};

            await getDoc(doc(db, "stores", "stores"))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.data();
                        const dataValues = Object.values(data);
                        const dataKeys = Object.keys(data);
                        let isFiltered = false;
                        let filterKey = '';
                        let filterData = '';
                        if (data[username].filter) {
                            isFiltered = true;
                            filterKey = Object.keys(data[username].filter)[0];
                            filterData = Object.values(data[username].filter)[0];
                        }
                        setRole(data[username].role);
                        for (let i = 0; i < dataValues.length; i++) {
                            if (!dataValues[i].role) {
                                if (isFiltered) {
                                    if (dataValues[i][filterKey] === filterData) {
                                        storesInfoKeys.push(dataKeys[i]);
                                        storesInfoValues.push(dataValues[i]);
                                    }
                                } else {
                                    storesInfoKeys.push(dataKeys[i]);
                                    storesInfoValues.push(dataValues[i]);
                                }
                            }
                        }
                        participatingStores = storesInfoValues.length;
                    } else {
                        console.log("No such document!");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

            await get(ref(database, 'managers_info/' + selectedTest.id))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        const dataKeys = Object.keys(data);
                        const dataValues = Object.values(data);
                        let answersScoreKeys = [];
                        let answersScoreValues = [];
                        for (let i = 0; i < dataKeys.length; i++) {
                            if (storesInfoKeys.find((key) => key === dataKeys[i])) {
                                answersScoreKeys.push(dataKeys[i]);
                                answersScoreValues.push(dataValues[i]);
                            }
                        }
                        for (let i = 0; i < answersScoreKeys.length; i++) {
                            managerData = {
                                ...managerData,
                                [answersScoreKeys[i]]: answersScoreValues[i],
                            }
                        }
                    } else {
                        console.log('No data available');
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            setManagerInfo(managerData);
            setStoresInfo(storesInfoValues);
            setStoresInfoKeys(storesInfoKeys);
            setLoading(false);
        })();
    },[selectedTest]);

    if (loading) {
        return (
            <></>
        )
    }
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
        const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
        return `${formattedDate} - ${formattedTime}`;
    }

    const writePhotoScore = (shopId, taskId, score) => {
        const databaseRef = ref(database, `managers_info/${taskId}/${shopId}`);
        // const databaseRef = database.ref(`managers_info/${taskId}/${shopId}/photo_score`);
        const updates = {
            photo_score: score
        };

        update(databaseRef, updates)
            .then(() => {
                console.log('Данные успешно обновлены');
            })
            .catch((error) => {
                console.error('Ошибка при обновлении данных:', error);
            });
    };

    const writeEfficiencyScore = (shopId, taskId, score) => {
        const databaseRef = ref(database,`managers_info/${taskId}/${shopId}`);
        const updates = {
            efficiency_score: score
        };
        update(databaseRef, updates)
            .then(() => {
                console.log('Данные успешно обновлены');
            })
            .catch((error) => {
                console.error('Ошибка при обновлении данных:', error);
            });
    };

    async function getImages(testId, shopId) {
        const storage = getStorage();
        const storageReference = storageRef(storage, `tests/${testId}/${shopId}`);
        const files = await listAll(storageReference);
        const urls = [];
        for (const file of files.items) {
            const url = await getDownloadURL(file);
            urls.push(url);
        }
        return urls;
    }

    function ImageListComponent({ testId, shopId }) {
        const [imageUrls, setImageUrls] = useState([]);

        useEffect(() => {
            async function fetchImageUrls() {
                try {
                    const urls = await getImages(testId, shopId);
                    setImageUrls(urls);
                } catch (error) {
                    console.log("Ошибка при получении URL-адресов изображений:", error);
                }
            }

            fetchImageUrls().then((urls) => {
                return (
                    <>
                        {imageUrls.length > 0 ? (
                            imageUrls.map((url, index) => (
                                <div style={{ padding: '5px' }}>
                                    <img key={index} src={url} alt={`Изображение ${index + 1}`}
                                         className="d-inline-block align-top mx-1 my-1"
                                         style={{ maxWidth: '90%', maxHeight: '95%', objectFit: 'contain' }}/>
                                </div>
                            ))
                        ) : (
                            <p>Изображения не найдены</p>
                        )}
                    </>
                );
            }).catch((error) => {
                console.error(error);
            });
        }, [testId, shopId]);

        return (
            <>
                {imageUrls.length > 0 ? (
                    imageUrls.map((url, index) => (
                        <div style={{ padding: '5px' }}>
                            <img key={index} src={url} alt={`Изображение ${index + 1}`}
                                 className="d-inline-block align-top mx-1 my-1"
                                 style={{ maxWidth: '90%', maxHeight: '95%', objectFit: 'contain' }}/>
                        </div>
                    ))
                ) : (
                    <p>Изображения не найдены</p>
                )}
            </>
        );
    }

    function GetPhotos({ index }) {
        return <ImageListComponent testId={selectedTest.id} shopId={storesInfoKeys[index]} />;
    }

    function updateScore(index, speedScore, photoScore) {
        const updatedStoresInfo = storesInfo.map((x, idx) => {
            if (idx !== index) {
                return x;
            }
            managerInfo[storesInfoKeys[index]]["efficiency_score"] = speedScore;
            managerInfo[storesInfoKeys[index]]["photo_score"] = photoScore;
            return {
                ...x,
                photoScore: photoScore + "/100",
                speedScore: speedScore + "/100",
            };
        });
        setStoresInfo(updatedStoresInfo);
    }

    function GetScore({ index })  {
        index = currentIndex;
        let startTime = selectedTest.start.seconds * 1000;
        if (managerInfo[storesInfoKeys[index]]) {
            return <div>
                <h5>Дата начала теста</h5>
                <h6>{formatTimestamp(startTime)}</h6>
                <h5>Дата окончания теста</h5>
                <h6>{formatTimestamp(managerInfo[storesInfoKeys[index]]["finish_time"])}</h6>
                <h4> </h4>
                <h5>Оценка времени</h5>
                <div className="rating-field" style={{ display: 'flex', alignItems: 'center' }} >
                    <input type="range" min="1" max="100" step="1" id="myRange" onInput={(e) => {
                        document.getElementById("rangeValue").textContent = e.target.value;
                    }}/>
                    <span id="rangeValue"  style={{ marginLeft: "10px" }} >50</span>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap',
                    justifyContent: 'center', maxHeight: '40vh', overflow: 'auto', marginBottom: '10px',
                    marginTop: '5px'}}>
                    <GetPhotos index={index}/>
                </div>
                <h5>Оценка фотографий</h5>
                <div className="rating-field" style={{ display: 'flex', alignItems: 'center' }} >
                    <input type="range" min="1" max="100" step="1" id="myPhotoRange" onInput={(e) => {
                        document.getElementById("rangePhotoValue").textContent = e.target.value;
                    }}/>
                    <span id="rangePhotoValue"  style={{ marginLeft: "10px" }} >50</span>
                </div>
                <button
                    className="my-button"
                    style={{
                        backgroundColor: '#007bff',
                        color: '#fff',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '10px',
                    }}
                    onClick={() => {
                        const rangeValue = document.getElementById('rangeValue');
                        const rangePhotoValue = document.getElementById('rangePhotoValue');
                        writeEfficiencyScore(storesInfoKeys[index], selectedTest.id, parseInt(rangeValue.textContent));
                        writePhotoScore(storesInfoKeys[index], selectedTest.id, parseInt(rangePhotoValue.textContent));
                        updateScore(index, rangeValue.textContent, rangePhotoValue.textContent);
                        closeModal();
                    }}
                >
                    Принять
                </button>
            </div>;
        }
    }
    const handleModalOpen = (index) => {
        setIsModalOpen(true);
        setCurrentIndex(index);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleLinkClick = (index) => {
        handleModalOpen(index);
    };
    function StoreScore() {
        for (let index = 0; index < storesInfo.length; index++) {
            let speedScore = "0/100";
            let photoScore = "0/100";
            let statusScore = "Не оценено";
            if (!managerInfo[storesInfoKeys[index]]) {
                storesInfo[index].speedScore = speedScore;
                storesInfo[index].statusScore = statusScore;
                storesInfo[index].photoScore = photoScore;
            } else {
                let isRated;
                if (managerInfo[storesInfoKeys[index]]["finish_time"]) {
                    if (managerInfo[storesInfoKeys[index]]["efficiency_score"]) {
                        speedScore = managerInfo[storesInfoKeys[index]]["efficiency_score"] + "/100";
                        isRated = true;
                    } else {
                        isRated = false;
                    }
                    if (managerInfo[storesInfoKeys[index]]["photo_score"]) {
                        photoScore = managerInfo[storesInfoKeys[index]]["photo_score"] + "/100";
                    } else {
                        isRated = false;
                    }
                    if (isRated) {
                        statusScore = "Оценено";
                    } else if (role === "manager") {
                        const linkStyle = {
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: 'blue',
                        };
                        statusScore =
                            <a id={"statusLink_" + index} style={linkStyle} onClick={() => handleLinkClick(index)}>Оценить</a>
                    }
                }
                storesInfo[index].speedScore = speedScore;
                storesInfo[index].statusScore = statusScore;
                storesInfo[index].photoScore = photoScore;
            }
        }
    }
    return (
        <div className="container">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 m-3">
                <h2>Оценка менеджера</h2>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        {/* Контент модального окна */}
                        <h2>Модальное окно</h2>
                        <p>Содержимое модального окна</p>
                        <button onClick={closeModal}>Закрыть</button>
                    </div>
                </div>
            )}
            <div className="flex-fill border p-3 m-3 bg-white rounded shadow">
                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}
                       style={{ maxHeight: '100vh', overflow: 'auto'}}>
                    <Modal.Header closeButton>
                        <h3 style={{textAlign: 'center'}}>Оценка менеджера</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <GetScore index={currentIndex}/>
                    </Modal.Body>
                </Modal>
                <div className="table-responsive">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th scope="col" style={{ textAlign: 'center' }}>Код магазина</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Название магазина</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Город</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Оценка скорости</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Оценка фотографий</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Состояние</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            storesInfo.map((store, index) =>
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{store["code"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["name"]}</td>
                                    <td style={{ textAlign: 'center' }}>{store["city"]}</td>
                                    <td id={"tdSpeed" + index} style={{ textAlign: 'center' }}>{store["speedScore"]}</td>
                                    <td id={"tdPhoto" + index} style={{ textAlign: 'center' }}>{store["photoScore"]}</td>
                                    <td id={"tdStatus" + index} style={{ textAlign: 'center' }}>{store["statusScore"]}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}