import { useState } from "react";

export function PictureGallery({ photosArray, onPhotoClick, paginate, currentPage }) {
    const [photosPerPage] = useState(15);

    const lastPhotoIndex = currentPage * photosPerPage;
    const firstPhotoIndex = lastPhotoIndex - photosPerPage;
    const currentPhotos = photosArray.slice(firstPhotoIndex, lastPhotoIndex);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(photosArray.length / photosPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            {
                currentPhotos.map((photo, index) => (
                    <img
                        onClick={() => onPhotoClick(firstPhotoIndex + index)}
                        key={index}
                        alt="amg"
                        src={photo.url}
                        height={250}
                        width={250}
                        className="d-inline-block align-top mx-1 my-1"
                    />
                ))
            }
            <ul className="pagination justify-content-center">
                {
                    pageNumbers.map((number) => (
                        <li key={number} className="page-item">
                            <a className="page-link" href="#" onClick={() => paginate(number)}>{number}</a>
                        </li>
                    ))
                }
                {/*<li className="page-item">*/}
                {/*    <a className="page-link" href="!#" aria-label="Previous">*/}
                {/*        <span aria-hidden="true">&laquo;</span>*/}
                {/*    </a>*/}
                {/*</li>*/}
                {/*<li className="page-item">*/}
                {/*    <a className="page-link" href="!#" aria-label="Next">*/}
                {/*        <span aria-hidden="true">&raquo;</span>*/}
                {/*    </a>*/}
                {/*</li>*/}
            </ul>
        </div>
    )
}
